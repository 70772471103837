
import { defineComponent } from 'vue';
import IconPath from '@/components/Icon/IconPath.vue';
import { numberToMoney } from '@/helpers/currency';
export default defineComponent({
  components: {
    IconPath,
  },
  data() {
    return {
      numberToMoney,
    };
  },
  computed: {
    classRate(): string {
      const rate = this.data?.interestRate;
      let name = '';
      if (rate >= 19) {
        name = 'green';
      }
      if (rate < 19 && rate > 15) {
        name = 'blue';
      }
      if (rate < 16 && rate > 10) {
        name = 'orange';
      }
      if (rate < 11) {
        name = 'red';
      }
      return name;
    },
  },
  props: {
    data: {
      type: Object,
    },
  },
});
