
import { defineComponent } from 'vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import MarketFilter from './MarketPlaceFilter.vue';
import MarketSearch from './MarketPlaceSearch.vue';
import MarketItem from './MarketPlaceItem.vue';
import BaseModal from '@/components/Base/BaseModal.vue';
import BaseButton from '@/components/Base/BaseButton.vue';
import SketetonLoader from '@/components/Base/BaseSkeletonLoader.vue';
import constRouter from '@/constants/constRouter';
import { mapGetters, mapActions } from 'vuex';
import InfiniteLoading from 'infinite-loading-vue3-ts';
export default defineComponent({
  components: {
    DefaultLayout,
    MarketSearch,
    MarketFilter,
    MarketItem,
    BaseModal,
    BaseButton,
    SketetonLoader,
    InfiniteLoading,
  },
  // setup() {
  //   const store = useStore();
  //   const data = store.getters.getListContract;
  //   if (data && data.length === 0) {
  //     store.dispatch('fetchListContract');
  //   }
  // },
  computed: {
    ...mapGetters({
      listData: 'getListContract',
      getCurrentContract: 'getCurrentContract',
      user: 'getUser',
    }),
  },
  data() {
    return {
      openModal: false,
      loading: false,
      page: 1,
      isNoResults: false,
      isComplete: false,
    };
  },

  methods: {
    ...mapActions({
      fetchListContract: 'fetchListContract',
    }),
    handleClick(contractId: string) {
      localStorage.setItem('contractId', contractId);
      if (this.user.nicNumber) {
        this.$router.push({
          name: constRouter.INVEST_DETAIL.name,
          params: { id: contractId },
        });
      } else {
        this.openModal = true;
      }
    },
    handleConfirm() {
      this.$router.push({
        name: constRouter.EKYC.name,
      });
    },
    closeModal() {
      this.openModal = false;
    },
    async infiniteHandler($state: any): Promise<void> {
      await this.fetchListContract();
      if (this.getCurrentContract?.length) {
        $state.loaded();
      } else {
        $state.complete();
      }
    },
  },
});
