import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e37760ca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "market-place-item" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = { class: "ml-3" }
const _hoisted_4 = { class: "small-precentage company" }
const _hoisted_5 = { class: "text-right" }
const _hoisted_6 = { class: "small-precentage" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_path = _resolveComponent("icon-path")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["icon", 'bg-soft' + _ctx.classRate])
      }, [
        _createVNode(_component_icon_path, {
          class: _normalizeClass('fill-' + _ctx.classRate)
        }, null, 8, ["class"])
      ], 2),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", null, _toDisplayString(_ctx.data.customer?.fullName), 1),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.data.customer?.companyName), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("h3", null, _toDisplayString(_ctx.numberToMoney(_ctx.data.approveAmount)) + " đ", 1),
      _createElementVNode("p", _hoisted_6, [
        _createTextVNode(_toDisplayString(_ctx.data.term) + " tháng - ", 1),
        _createElementVNode("span", {
          class: _normalizeClass('text-' + _ctx.classRate)
        }, _toDisplayString(parseFloat(_ctx.data.interestRate)) + "%", 3)
      ])
    ])
  ]))
}