
import { defineComponent } from 'vue';
import IconStart from '@/components/Icon/IconStart.vue';
import IconArrowUp from '@/components/Icon/IconArrowUp.vue';
import IconStart2 from '@/components/Icon/IconStart2.vue';
export default defineComponent({
  name: 'MarketFilter',
  components: {
    IconStart,
    IconArrowUp,
    IconStart2,
  },
});
