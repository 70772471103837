export function numberToMoney(_number: number) {
  if (_number && +_number) {
    const number = +_number;
    return `${number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
  }
  if (+_number === 0) return `${_number}`;
  return null;
}

export function moneyToNumber(_money: string) {
  let money = _money;
  if (money) {
    money = money.replace(/,/g, '');
    if (Number.isInteger(+money)) {
      return +money;
    }
  }
  return null;
}

export function calFee(money: number, fee: number) {
  return Math.round(Math.max((money * fee) / 100, fee * 10000) / 100) * 100;
}

export function calMonthlyPayment(money: number, fee: number, term: number) {
  return Math.round(Math.max((money + money * fee) / term, 0) / 100) * 100;
}
